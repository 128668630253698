import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LogService } from './core/log.service';
import { AuthService } from './core/auth.service';
import { UtilService } from './core/util.service';
import { SiteService } from './core/site.service';
import { RoomService } from './core/room.service';
import { UserService } from './core/user.service';
import { VersionService } from './core/version.service';
import { AlertNoticeService } from './shared/alert-notice/alert-notice.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  email = 'signage@ghostaurant.co';
  myVersion: string;
  latestVersion: string;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private userService: UserService,
    private siteService: SiteService,
    private roomService: RoomService,
    private versionService: VersionService,
    private utilService: UtilService,
    private alertNoticeService: AlertNoticeService,
    private logService: LogService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    this.signIn();
    this.observeVersion();
  }

  async signIn() {
    try {
      const isSignIn = await this.authService.signinWithEmailAndPassword(this.email, '4EBE3283-D48F-47FB-923E-05767A24C85E');
      if (isSignIn) {
        this.userService.observe(this.email);

        while (this.userService.user === undefined) {
          console.log('Waiting for the first sync with Firestore user');
          await this.sleep(200);
        }

        this.siteService.observe();
        this.roomService.observe();

        const myVersion = this.versionService.myVersion;
        this.myVersion = myVersion;
        this.logService.info(`로그인 성공. myVersion = ${myVersion}`, this.email );
      }
    } catch (error) {
      this.alertNoticeService.noticeAlert(error);
      this.logService.withToastrCatch(error, `로그인 실패`);
    }
  }

  observeVersion() {
    this.myVersion = this.versionService.myVersion;
    this.latestVersion = this.versionService.latestVersion;

    this.versionService.latestVersionSubject.subscribe(lastesVersion => {
      this.latestVersion = lastesVersion;
      if (this.myVersion !== this.latestVersion) {
        this.logService.info(`singage : 업데이트'`);
        this.utilService.toastrWarning('새 버전이 있습니다. 잠시후 자동으로 업데이트 합니다.', undefined, 10000);
        this.reload();
      }
    });
  }

  async sleep(ms: number) {
    return new Promise(resolve => {
      setTimeout(() => resolve(true), ms);
    });
  }

  async reload() {
    await this.sleep(10000);
    window.location.reload();
  }
}
