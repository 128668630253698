import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomePage } from './home/home.page';
import { HomeGuardService } from './home-guard.service';
import { InitGuardService } from './init-guard.service';
import { SiteMapComponent } from './site-map/site-map.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: SiteMapComponent,
    canActivate: [InitGuardService]
  },
  {
    path: ':site',
    pathMatch: 'full',
    component: HomePage,
    canActivate: [InitGuardService, HomeGuardService],
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
