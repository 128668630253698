import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import { VersionDoc } from './schema';
import { UtilService } from './util.service';

const myVersion = environment.version;
const versionDocPath = 'conf/version';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  // tslint:disable-next-line: variable-name
  _latestVersion = myVersion;
  isDifferent = false;
  latestVersionSubject = new BehaviorSubject<string>(this._latestVersion);

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService
  ) {
    this.observeVersion();
  }

  get myVersion() {
    return environment.version;
  }

  get latestVersion() {
    return this._latestVersion;
  }

  /**
   *
   */
  observeVersion() {
    // let doc: AngularFirestoreDocument<VroongPickup>;

    const doc = this.db.doc<VersionDoc>(versionDocPath);

    doc.valueChanges().subscribe(version => {
      if (version === undefined) {
        // 'Could not reach Cloud Firestore backend.'와 같은 에러 발생시 데이터 읽기가 실패해서 undefined가 올 수 있다.
        this.utilService.toastrError('Firestore와의 통신에 문제가 있습니다. 네트워크 문제이거나 일시적인 구글 서비스 장애일 수 있습니다. 리로드해서 재시도하시거나 계속 발생시 개발자에거 알려주시기 바랍니다.');
        return;
      }

      this._latestVersion = version.signageVer;
      console.dir(`lateast version = ${this.latestVersion}`);
      this.latestVersionSubject.next(this._latestVersion);
    });
  }
}


