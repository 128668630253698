import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { SiteService } from '../core/site.service';
import { LoadingService } from '../shared/loading/loading.service';
import { LogService } from '../core/log.service';

interface SiteMapViewModel {
  siteKey: string;
  siteName: string;
  sortKey: number;
  imageUrl: string;
}

@Component({
  templateUrl: './site-map.component.html',
  styleUrls: ['./site-map.component.scss']
})
export class SiteMapComponent implements OnInit, OnDestroy {
  public siteMapViewModel: SiteMapViewModel[] = [];
  public disabled = false;
  private siteDocSubscription: Subscription;

  constructor(
    private siteService: SiteService,
    private router: Router,
    private loadingService: LoadingService,
    private logService: LogService
  ) { }

  ngOnInit() {
    this.observeOrganizationConf();
    document.title = '고스트키친';
  }

  ngOnDestroy() {
    if (this.siteDocSubscription) {
      this.siteDocSubscription.unsubscribe();
    }
  }

  observeOrganizationConf() {
    this.siteDocSubscription = this.siteService.latestSubject.subscribe(siteDoc => {
      const imageUrlPrefix = 'https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/site/';
      const sites = siteDoc;

      this.siteMapViewModel = Object.entries(sites)
      .map(([siteKey, siteValue]) => {
        return {
          siteKey,
          siteName: siteValue.siteName.replace('고스트키친 ', ''),
          sortKey: siteValue.sortKey,
          imageUrl: imageUrlPrefix + siteKey + '-front.jpg'
        };
      })
      .filter(homeViewModel => homeViewModel.sortKey > 0)
      .sort((a, b) => (b.sortKey - a.sortKey));
    });
  }

  async goSite(site: string) {
    if (!this.disabled) {
      try {
        this.disabled = true;
        await this.loadingService.presentLoading();
        await this.router.navigate([ `/${site}` ]);
      } catch (error) {
        this.logService.withToastrCatch(error, `[goSite]에서 예외 발생.`);
      }
      this.disabled = false;
    }
  }
}
