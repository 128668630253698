import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { UnifiedMenuDoc } from './schema';
import { BehaviorSubject, Subscription } from 'rxjs';
// import { UtilService } from './util.service';
// import { environment } from '../../environments/environment';
// import { debugLog } from './util';
// import { map } from 'rxjs/operators';

const collectionPath = 'unifiedMenu';

@Injectable({
  providedIn: 'root'
})
export class UnifiedMenuService {
  currentSite: string;

  unifiedMenuForSite: UnifiedMenuDoc[];
  latestUnifiedMenuForSiteSubject = new BehaviorSubject<UnifiedMenuDoc[]>([]);
  unifiedMenuForSiteSubscription: Subscription;

  currentShop: string;

  unifiedMenuForShop: UnifiedMenuDoc;
  latestUnifiedMenuForShopSubject = new BehaviorSubject<UnifiedMenuDoc>(null);
  unifiedMenuForShopSubscription: Subscription;

  constructor(
    private db: AngularFirestore,
  ) { }

  public restartObservingForSite(site: string) {
    if (this.currentSite === site) {
      return;
    }

    this.unifiedMenuForSite = undefined;
    this.latestUnifiedMenuForSiteSubject.next([]);

    if (this.unifiedMenuForSiteSubscription) {
      this.unifiedMenuForSiteSubscription.unsubscribe();
      this.unifiedMenuForSiteSubscription = null;
    }

    this.currentSite = site;
    this.observeMenu('site', site);
  }

  public restartObservingForShop(shopNo: string, forceRestart: boolean = false) {
    if (this.currentShop === shopNo && !forceRestart) {
      return;
    }

    this.unifiedMenuForShop = undefined;

    if (this.unifiedMenuForShopSubscription) {
      this.unifiedMenuForShopSubscription.unsubscribe();
      this.unifiedMenuForShopSubscription = null;
    }

    this.currentShop = shopNo;
    this.observeMenu('shopNo', shopNo);
  }

  /**
   * 주어진 key/value 조건에 맞는 메뉴 목록을 가져온다.
   *
   * @param key 'organization' | 'site' | 'room' | 'shopNo'
   */
  private observeMenu(key: 'organization' | 'site' | 'room' | 'shopNo', value: string) {
    // debugLog(`${this.constructor.name}::observeMenu for ${key}:${value}`);
    const queryFn: QueryFn = ref => {
      const query = ref.where(key, '==', value);
      return query;
    };

    const collection = this.db.collection<UnifiedMenuDoc>(collectionPath, queryFn);

    // 디버깅용
    // if (environment.production === false) {
    //   collection.stateChanges().pipe(
    //     map(actions => actions.map(action => {
    //       return { _type: action.type, ...action.payload.doc.data() };
    //     }))
    //   ).subscribe(menus => {
    //     for (const menu of menus) {
    //       // debugLog(`[${menu._id}] ${menu.room}/${menu.shopName}`);
    //     }
    //   });
    // }

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.
    if (key === 'site') {
      this.unifiedMenuForSiteSubscription = collection.valueChanges().subscribe(doc => {
        this.unifiedMenuForSite = doc;
        this.latestUnifiedMenuForSiteSubject.next(doc);
      });
    } else if (key === 'shopNo') {
      this.unifiedMenuForShopSubscription = collection.valueChanges().subscribe(doc => {
        this.unifiedMenuForShop = doc[0];
        this.latestUnifiedMenuForShopSubject.next(doc[0]);
      });
    }
  }
}
