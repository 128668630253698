import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { SiteDoc, SiteDocs } from './schema';
import { UserService } from './user.service';

const collectionPath = 'site';

/**
 * 최신 버전을 유지하며 변경될 때마다 알려준다.
 */
@Injectable({
  providedIn: 'root'
})
export class SiteService {
  public site: SiteDocs = {};
  public latestSubject = new BehaviorSubject<SiteDocs>({});

  constructor(
    private db: AngularFirestore,
    private userService: UserService
  ) {
  }

  public observe() {
    const organization = this.userService.organization;
    const queryFn: QueryFn = ref => ref.where('organization', '==', organization);

    const collection = this.db.collection<SiteDoc>(collectionPath, queryFn);
    collection.valueChanges().subscribe(docs => {
      const siteDocs = {};

      for (const doc of docs) {
        siteDocs[doc._id] = doc;
      }

      this.site = siteDocs;
      this.latestSubject.next(this.site);
    });
  }
}

