import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { UnifiedMenuService } from './core/unified-menu.service';

/**
 * Site 페이지를 표시하기 전에 준비해야 할 것이 있으면 기다리도록 한다.
 * - unifiedMenu
 */
@Injectable({
  providedIn: 'root'
})
export class HomeGuardService implements CanActivate {
  constructor(
    private unifiedMenuService: UnifiedMenuService,
  ) { }

  promiseForInit(site: string) {
    return new Promise<boolean>((resolve) => {
      this.unifiedMenuService.restartObservingForSite(site);
      this.unifiedMenuService.latestUnifiedMenuForSiteSubject.subscribe(docs => {
        if (docs.length > 0) {
          resolve(true);
        }
      });
    });
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const currentSite = route.paramMap.get('site');
    return this.promiseForInit(currentSite);
  }
}

