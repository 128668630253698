import { Subscription, combineLatest, merge } from 'rxjs';
import { debounceTime, skip, first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { SiteService } from '../core/site.service';
import { RoomService } from '../core/room.service';
import { SignageRoomItem } from '../core/schema-signage';
import { UnifiedMenuService } from '../core/unified-menu.service';
import { UnifiedMenuFood, UnifiedMenuFoodGrp } from '../core/schema';
import { LoadingService } from '../shared/loading/loading.service';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit, OnDestroy {
  rooms: SignageRoomItem[] = [];
  siteName: string;
  currentSiteKey: string;
  qrcodeUrl: string;
  private unifiedMenuAndOrganizationConfSubscription: Subscription;

  constructor(
    private siteService: SiteService,
    private roomService: RoomService,
    private unifiedMenuService: UnifiedMenuService,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.loadingService.dismissLoading();
    const currentSiteKey = this.route.snapshot.paramMap.get('site');
    this.currentSiteKey = currentSiteKey;
    this.qrcodeUrl = `https://face.ghostaurant.co/${currentSiteKey}`;
    this.subscribeUnifiedMenu(currentSiteKey);
  }

  ngOnDestroy() {
    if (this.unifiedMenuAndOrganizationConfSubscription) {
      this.unifiedMenuAndOrganizationConfSubscription.unsubscribe();
    }
  }

  subscribeUnifiedMenu(currentSiteKey: string) {
    const unifiedMenuSubject = this.unifiedMenuService.latestUnifiedMenuForSiteSubject;
    const roomSubejct = this.roomService.latestSubject;

    // debounce시 비어지는 초기값을 넣어주기 위함.
    const initialCombineLatestQuery = combineLatest([unifiedMenuSubject, roomSubejct]).pipe( first() );
    const debounceCombineLatestQuery = combineLatest([unifiedMenuSubject, roomSubejct]).pipe(
      skip(1),
      debounceTime(10000) // 여러개의 업소로부터 이벤트가 있을 경우를 대비해서 10초의 debouncing을 준다.
    );

    // tslint:disable-next-line:max-line-length
    this.unifiedMenuAndOrganizationConfSubscription = merge(initialCombineLatestQuery, debounceCombineLatestQuery)
    .subscribe(([unifiedMenuDoc, roomDocs]) => {
      const initRoomItems = unifiedMenuDoc
      .filter(unifiedMenu => (
          // 현재 사이트의 업소만 표시한다.
          unifiedMenu.site === currentSiteKey
          // 사이니지에 노출을 허용한 곳만 표시한다.
          && roomDocs[unifiedMenu.room].enableSignage
          // 운영중인 업소만을 표시한다.
          && roomDocs[unifiedMenu.room].live
          && !roomDocs[unifiedMenu.room].virtual
          // 최소 하나의 메뉴(그룹)가 있는 업소만을 표시한다.
          && unifiedMenu.menus[0]
          // 배민에서 광고를 사용중인가?
          // 2020-11-26 나중에 Ad_Yn 필드를 추가해서 없는 경우도 있을 수 있기 때문에 'N'이 아닌 경우는 허용하도록 했다. 나중에는 'Y'인 경우만 허용하도록 한다.
          && unifiedMenu.baeminShopInfo?.Ad_Yn !== 'N'
        ))
        .map(unifiedMenu => {
          const foods = this.getFoodsAtleast(4, unifiedMenu.menus);
          const thumbnailUrl = this.getFirstMenuUcloudImageUrl(foods);
          const room = roomDocs[unifiedMenu.room];

          return {
            shopName: unifiedMenu.shopName,
            roomKey: unifiedMenu.room,
            roomNo: Number(unifiedMenu.room.split('-')[2]),
            foods,
            thumbnailUrl,
            section: room.section,
            /** 2021.01.07 배민 운영 상태를 무시하는 '손가락 강제 오픈' 추가 */
            isAvailable: unifiedMenu.baeminShopInfo && (room.forceFingerOpen === true || unifiedMenu.baeminShopInfo.Ord_Avail_Yn === 'Y')
          };
        })
        // 메뉴의 이미지가 있어야한다.
        .filter(siteViewModel => siteViewModel && siteViewModel.thumbnailUrl);

      const uniqueRoomItems = this.getUniqueRoomViewModel(initRoomItems);
      const siteName = this.siteService.site[currentSiteKey].siteName;
      document.title = siteName;
      this.siteName = siteName.replace(' ', ' | ');
      this.rooms = uniqueRoomItems;
    });
  }

  /**
   * 배민 메뉴 이미지 주소를 미러링 해둔 ucloud의 이미지 주소로 변경한다.
   */
  getFirstMenuUcloudImageUrl(foods: UnifiedMenuFood[]) {
    // tslint:disable-next-line:max-line-length
    const ucloudUrlPrefix = 'https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/toe-menu-images';
    const hasFoodImage = foods.find(food => (food.imageUrl && food.imageUrl.length > 0));

    return hasFoodImage
      ? `${ucloudUrlPrefix}${hasFoodImage.imageUrl.split('.com')[1]}`
      : undefined;
  }

  /**
   * 대표 메뉴가 min 이하인 경우 다른 메뉴그룹의 메뉴로 채운다.
   * @param min : 표시할 메뉴의 수
   */
  getFoodsAtleast(min: number, menus: UnifiedMenuFoodGrp[]) {
    let foods: UnifiedMenuFood[] = [];
    menus.forEach(menu => {
      if (foods.length < min) {
        foods = [...foods, ...menu.foods];
      }
    });
    return foods.slice(0, min);
  }

  /**
   * 같은 호실인 경우 최초 하나의 업소정보만 노출한다.
   */
  getUniqueRoomViewModel(siteViewModel: SignageRoomItem[]) {
    return siteViewModel.filter((a, index) => (
      index === siteViewModel.findIndex(b => a.roomKey === b.roomKey)
    ));
  }
}
