import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router, UrlTree
} from '@angular/router';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
// import { debugLog } from './core/util';
import { RoomService } from './core/room.service';
import { SiteService } from './core/site.service';

/**
 * 개별 페이지를 표시하기 전에 준비해야 할 것이 있으면 기다리도록 한다.
 * - siteDocs, roomDocs
 */
@Injectable({
  providedIn: 'root'
})
export class InitGuardService implements CanActivate {
  constructor(
    private roomService: RoomService,
    private siteService: SiteService,
    private router: Router
  ) { }

  promiseForInit(currentSite: string) {
    return new Promise<boolean|UrlTree>(async (resolve) => {
      const siteSubject = this.siteService.latestSubject;
      const roomSubject = this.roomService.latestSubject;
      const parsedUrl = this.router.parseUrl('');

      try {
        await combineLatest([ siteSubject, roomSubject ]).pipe(
          takeWhile(([ siteDocs, roomDocs ]) => !(Object.keys(siteDocs).length > 0 && Object.keys(roomDocs).length > 0))
        ).forEach(() => {});

        if (this.siteService.site[currentSite] !== undefined || currentSite === null) {
          resolve(true);
        } else {
          resolve(parsedUrl);
        }
      } catch (error) {
        resolve(parsedUrl);
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // debugLog(`canActivate: '${route.url}'`);
    const currentSite = route.paramMap.get('site');
    return this.promiseForInit(currentSite);
  }
}

