import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingElement: HTMLIonLoadingElement;

  constructor(
    private loadingController: LoadingController
  ) { }

  public async presentLoading() {
    this.loadingElement = await this.loadingController.create({
      message: `Please wait...`,
      duration: 60000
    });
    return this.loadingElement.present();
  }

  public async dismissLoading() {
    return this.loadingElement?.dismiss();
  }
}
