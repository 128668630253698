import { trigger, style, transition, animate } from '@angular/animations';
import { Component, OnInit, Input, HostListener, OnChanges } from '@angular/core';

import { SignageRoomItem, SignageListViewModel } from '../core/schema-signage';
import { UtilService } from '../core/util.service';

@Component({
  selector: 'app-angular-slider',
  templateUrl: './angular.slider.component.html',
  styleUrls: ['./angular.slider.component.scss'],
  animations: [
    trigger('viewAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1500ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('1500ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class AngularSliderComponent implements OnInit, OnChanges {
  @Input() rooms: SignageRoomItem[];

  public isSafari = false;
  public viewList: SignageListViewModel[];
  public currentIndex: number;

  constructor(
    private utilService: UtilService
  ) {}

  ngOnChanges() {
    this.initialize();
  }

  ngOnInit() {
    this.isSafari = navigator.userAgent.toLowerCase().indexOf('safari') !== -1;
    const animationTimePerMS = 9000;
    const startAt = 0;

    this.initialize();
    // 운영중인 업소가 있는 경우에만 애니메이션을 시작한다.
    if (this.viewList.length > 0) {
      this.timeLine(startAt, animationTimePerMS);
    }
  }

  initialize() {
    this.viewList = this.sliceRooms(this.rooms, 15, 3);
  }

  async timeLine(start: number, ms: number) {
    const endOfList = this.viewList.length - 1;

    this.currentIndex = start;
    while (true) {
      let animationTimer = ms;
      if (this.currentIndex > endOfList) {
        // 한 바퀴 돌때마다 Rfresh를 하고 싶다면 아래 주석 사용
        // this.initialize();
        this.currentIndex = start;
      }

      const itemLength = this.viewList[this.currentIndex].rooms.length;
      if (itemLength < 3) {
        animationTimer = animationTimer / 3 * itemLength;
      }

      await this.sleep(animationTimer);
      ++this.currentIndex;
    }
  }

  async sleep(ms: number) {
    return new Promise(resolve => {
      setTimeout(() => resolve(true), ms);
    });
  }

  /**
   * 지점의 호실이 listItemLength 이상인 경우 슬라이드를 나눠준다.
   */
  sliceRooms(roomViewModel: SignageRoomItem[], gridLimit: number, sectionLimit: number) {
    const allRoomsSlideItem: SignageListViewModel[] = [];
    for (let i = 0; i < roomViewModel.length; i += gridLimit) {
      allRoomsSlideItem.push({
        rooms: roomViewModel.slice(i, i + gridLimit),
        type: 'grid'
      });
    }

    for (let i = 0; i < roomViewModel.length; i += sectionLimit) {
      allRoomsSlideItem.push({
        rooms: roomViewModel.slice(i, i + sectionLimit),
        type: 'section'
      });
    }

    return allRoomsSlideItem;
  }

  /**
   * 정렬된 flex view를 위해 화면의 최대 슬라이드 개수보다 적은 경우 더미로 채워넣는다.
   */
  fillWithDummyItem(verticalSlideList: SignageListViewModel[], maxLength: number) {
    return verticalSlideList.map(slide => {
      if (slide.rooms.length < maxLength) {
        const allRoomsViewModelwithDummy = slide.rooms;
        const itemLength = slide.rooms.length;
        for (let i = itemLength; i < 15; i++) {
          allRoomsViewModelwithDummy.push({
            shopName: undefined,
            roomKey: undefined,
            roomNo: undefined,
            thumbnailUrl: undefined,
            foods: undefined
          });
        }
        slide.rooms = allRoomsViewModelwithDummy;
      }

      return slide;
    });
  }

  // 사용자 키보드 이벤트
  @HostListener('window:keyup', ['$event'])
  public keyEvent(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.toggleFullScreen();
    }
  }

  private toggleFullScreen() {
    if (this.isSafari) {
      if (!(document as any).webkitFullscreenElement) {
        (document.documentElement as any).webkitRequestFullscreen();
      } else {
        this.utilService.toastrInfo('Safari에서는 전체화면 종료를 위해 ESC를 이용해주세요.');
      }
    } else {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        document?.exitFullscreen();
      }
    }
  }
}
